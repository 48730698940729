<template>
    <div class="detail-content">
        <el-scrollbar class="scrollbar-list">
            <div class="detail-img-bg">
                <div class="school-logo-info">
                    <div class="school-logo">
                        <img :src="logo?logo:require('../../../assets/images/yike_logo.png')"/>
                    </div>
                    <p class="school-title">{{name}}</p>
                </div>
<!--                <div class="detail-img">-->
<!--                    -->
<!--&lt;!&ndash;                    <div class="detail-right-img"></div>&ndash;&gt;-->
<!--                </div>-->
            </div>
            <div class="detail-box">
                <div class="detail-item">
                    <div class="detail-info" v-for="item in content">
                        <div class="classic-detail-title">{{item.name}}</div>
                        <div class="classic-detail-content" v-html="item.content"></div>
                    </div>
                </div>
            </div>
            <div class="official-footer">
                <OfficialFooter></OfficialFooter>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {oneCase} from '@/utils/apis'
    import OfficialFooter from '@/components/OfficialFooter'
    export default {
        name: "Detail",
        components: {
            OfficialFooter,
        },
        data(){
            return{
                logo:'',
                name:'',
                content:[]
            }
        },
        mounted() {
            if (this.$route.query.id){
                this.getCaseById(this.$route.query.id)
            }

        },
        methods:{
            getCaseById(id) {
                // let formData = new FormData();
                // formData.append("id", id);
                let formData = {
                    id: id
                }
                oneCase(formData).then(res => {
                    this.logo = res.data.logo_path;
                    this.name = res.data.name;
                    this.content = res.data.content;

                }).catch(err => {
                    console.error(err)
                })
            },
        }
    }
</script>

<style scoped lang="scss">
 .detail-content{
     display: flex;
     flex-direction: column;
     flex: 1;
     height: 100%;
     .scrollbar-list{
         height: 100%;
         /deep/ .el-scrollbar__wrap {
             overflow-x: hidden;
         }
         .detail-img-bg{
             align-items: center;
             display: flex;
             height: 466px;
             padding: 0 300px;
             background: url("../../../assets/images/detail-bg.jpg")no-repeat center;
                 .school-logo-info{
                     width: 50%;
                     display: flex;
                     flex-direction: column;
                     align-items: center;
                     .school-logo{
                         width: 138px;
                         height: 138px;
                         border-radius: 50%;
                         img{
                             max-height: 100%;
                             max-width: 100%;
                             /*border-radius: 50%;*/
                         }
                     }
                     .school-title{
                         font-size: 48px;
                         color: #fff;
                         height: 48px;
                         line-height: 48px;
                         margin-top: 57px;
                     }
                     p{
                         list-style: none;
                         margin: 0;
                         padding: 0;
                         text-decoration: none;
                     }
                 }
                 /*.detail-right-img{*/
                 /*    width: 50%;*/
                 /*    height: 466px;*/
                 /*    background: url("../../../assets/images/detail-right-bg.png")no-repeat right center;*/
                 /*}*/
         }
         .detail-box{
             background: url("../../../assets/images/jingpin-bg.jpg")no-repeat center;
             background-size: cover;
             min-height: 600px;
             .detail-item{
                 width: 1200px;
                 margin: 0 auto;
                 .detail-info{
                     padding: 74px 0 20px;
                     box-sizing: border-box;
                     .classic-detail-title{
                         font-size: 18px;
                         color: #222;
                         box-sizing: border-box;
                         position: relative;
                         padding-left: 20px;
                         &:before{
                             content: '';
                             height: 20px;
                             width: 6px;
                             background: #F36F21;
                             border-radius: 4px;
                             position: absolute;
                             left: 0;
                             top: 3px;
                         }
                     }
                     .classic-detail-content{
                         text-indent:2em;
                         p{
                             list-style: none;
                             margin: 0;
                             padding: 0;
                         }
                     }
                 }
             }
         }
     }
 }
</style>